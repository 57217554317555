import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import AvatarEditor from 'react-avatar-editor';
import { motion } from 'framer-motion';

const UpdateAvatar = ({ closeModal }) => {
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const { user, setUser } = useContext(AuthContext);
  const editorRef = useRef();

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Неподдерживаемый формат! Используйте JPG, PNG или GIF.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('Размер файла превышает 5MB!');
      return;
    }

    setAvatar(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleUpload = async (formData) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setUser(response.data.user);
      toast.success('Аватар успешно обновлён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка обновления аватара:', error);
      toast.error('Не удалось обновить аватар.');
    } finally {
      setIsLoading(false);
      toast.dismiss();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!avatar) {
      toast.error('Выберите изображение.');
      return;
    }

    setIsLoading(true);
    toast.loading('Загрузка...');

    if (editorRef.current) {
      editorRef.current.getImageScaledToCanvas().toBlob(
          (blob) => {
            const formData = new FormData();
            formData.append('avatar', blob);
            handleUpload(formData);
          },
          'image/jpeg',
          0.92
      );
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-avatar`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUser((prevUser) => ({ ...prevUser, avatar: null }));
      toast.success('Аватар успешно удалён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка удаления аватара:', error);
      toast.error('Не удалось удалить аватар.');
    }
  };

  return (
    <div
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    >
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="relative bg-white p-8 rounded-lg shadow-lg max-w-md w-full dark:bg-neutral-900 dark:text-white"
        >
          <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-700 hover:text-violet-400 transition duration-200 dark:text-white dark:hover:text-violet-400"
          >
            <i className="fas fa-times text-xl cursor-pointer"></i>
          </button>
          <motion.h2
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="text-2xl font-bold text-gray-800 mb-6 text-center dark:text-white"
          >
            Изменить аватар
          </motion.h2>

          {user.avatar && (
              <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="mb-6 text-center"
              >
                <p className="text-gray-700 font-semibold mb-2">Текущий аватар:</p>
                <img
                    src={user.avatar}
                    alt="Current Avatar"
                    className="w-32 h-32 rounded-full object-cover mx-auto"
                />
              </motion.div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
            >
              <label className="block text-gray-700 font-semibold mb-2 dark:text-white">
                Выберите новый аватар:
              </label>
              <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-violet-400 transition duration-150"
              />
            </motion.div>

            {preview && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="mb-4 text-center"
                >
                  <p className="text-gray-700 font-semibold mb-2">Предварительный просмотр:</p>
                  <AvatarEditor
                      ref={editorRef}
                      image={preview}
                      width={300}
                      height={300}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={scale}
                      rotate={0}
                      className="rounded-full mx-auto"
                  />
                </motion.div>
            )}

            {preview && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                >
                  <label className="block text-gray-700 font-semibold mb-2 dark:text-white">
                    Масштаб:
                  </label>
                  <input
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-violet-400 transition duration-150"
                  />
                </motion.div>
            )}

            <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                type="submit"
                className={`w-full bg-violet-500 hover:bg-violet-600 text-white font-semibold py-2 rounded-lg transition duration-200 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
            >
              {isLoading ? 'Загрузка...' : 'Сохранить'}
            </motion.button>
          </form>

          <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onClick={handleDeleteAvatar}
              className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 rounded-lg mt-4 transition duration-200"
              disabled={isLoading}
          >
            Удалить аватар
          </motion.button>
        </motion.div>
    </div>
  );
};

export default UpdateAvatar;
