import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useWindowSize from "../../hooks/useWindowSize";

function FriendSuggestions() {
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { width } = useWindowSize();

    useEffect(() => {
        const fetchSuggestions = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/friend-recommendations`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSuggestions(response.data);
            } catch (error) {
                setError('Не удалось загрузить рекомендации. Попробуйте позже.');
            } finally {
                setLoading(false);
            }
        };

        fetchSuggestions();
    }, []);

    const handleRemoveSuggestion = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/friend-recommendations/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setSuggestions((prev) => prev.filter((suggestion) => suggestion.id !== id));
        } catch {
            alert('Не удалось удалить рекомендацию. Попробуйте позже.');
        }
    };

    const handleAddFriend = async (id) => {
        console.log("Sending friend request with friend_id:", id); // Debugging to ensure id is not undefined

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/friends`,
                { friend_id: id }, // Payload matches backend expectation
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            alert("Заявка отправлена");
            setSuggestions((prev) => prev.filter((suggestion) => suggestion.recommended_friend.id !== id));
        } catch (error) {
            if (error.response && error.response.status === 422) {
                console.error('Validation error:', error.response.data.errors);
                alert('Ошибка валидации: ' + JSON.stringify(error.response.data.errors));
            } else {
                console.error('Ошибка при отправке заявки!', error);
                alert('Не удалось отправить заявку. Попробуйте позже.');
            }
        }
    };


    if (loading) return <p className="text-gray-600">Загрузка рекомендаций...</p>;
    if (error) return <p className="text-red-600">{error}</p>;

    return (
        <div className="p-4 bg-white rounded-lg shadow-md dark:bg-neutral-800 mt-4 mx-auto">
            <h1 className="text-2xl font-bold mb-4 dark:text-white">Возможно вы знакомы</h1>
            <div className={`grid sm:grid-cols-2 lg:grid-cols-3 gap-6 ${width < 768 ? 'grid-cols-2' : ''}`}>
                {suggestions.length === 0 ? (
                    <p className="dark:text-gray-300">Нет рекомендаций</p>
                ) : (
                    suggestions.map((suggestion) => (
                        <div key={suggestion.id} className={`bg-white dark:bg-neutral-700 rounded-lg shadow-md p-4 relative`}>
                            <button
                                onClick={() => handleRemoveSuggestion(suggestion.id)}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-500"
                            >
                                ✕
                            </button>
                            <img
                                src={suggestion.recommended_friend.avatar
                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${suggestion.recommended_friend.avatar}`
                                    : `https://ui-avatars.com/api/?name=${suggestion.recommended_friend.name}&background=random`
                                }
                                alt={suggestion.recommended_friend.name || "Profile"}
                                className="w-32 h-32 object-cover rounded-full mx-auto mb-3"
                            />
                            <div className="text-center">
                                <p className="text-lg font-semibold dark:text-white">{suggestion.recommended_friend.name}</p>
                                {/*{suggestion.recommended_friend.city && (*/}
                                {/*    <p className="text-sm dark:text-gray-300">{suggestion.recommended_friend.city}</p>*/}
                                {/*)}*/}
                                {/*{suggestion.recommended_friend.age && (*/}
                                {/*    <p className="text-sm text-gray-500 dark:text-gray-400">{suggestion.recommended_friend.age} лет</p>*/}
                                {/*)}*/}
                                {suggestion.mutual_friends_count > 0 && (
                                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">{suggestion.mutual_friends_count} общих друзей</p>
                                )}
                            </div>
                            <button
                                onClick={() => handleAddFriend(suggestion.recommended_friend.id)}
                                className="w-full mt-4 py-2 bg-violet-500 text-white font-medium rounded-md hover:bg-violet-600 transition duration-200"
                            >
                                <i className="fas fa-user-plus mr-2"></i> Добавить
                            </button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default FriendSuggestions;
