import React, { useState, useEffect, useContext } from 'react';
import Aside from './Aside';
import PostList from './PostList';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from './BottomNav';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { motion } from 'framer-motion';
import RightSidebar from './RightSidebar';

function Home() {
    const { isLoggedIn, user } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);
    const { width } = useWindowSize();
    const [userData, setUserData] = useState(user);

    useEffect(() => {
        const fetchUserData = async () => {
            if (isLoggedIn && !userData) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUserData(response.data);
                } catch (error) {
                    console.error('Error fetching user data', error);
                }
            }
        };
        fetchUserData();
    }, [isLoggedIn, userData]);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        const handleScroll = () => {
            setShowCreateButton(window.scrollY <= lastScrollY);
            lastScrollY = window.scrollY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const addPost = (newPost) => setPosts([newPost, ...posts]);

    const renderFloatingButton = () => (
        <motion.button
            onClick={openModal}
            className={`fixed bottom-16 right-4 flex items-center justify-center bg-violet-500 hover:bg-violet-600 text-white font-semibold rounded-full shadow-lg transition-all duration-300 z-30
                ${width < 768 ? 'w-10 h-10' : 'px-3 py-3 w-12 h-12'}`}
            initial={{ scale: 0 }}
            animate={{ scale: showCreateButton ? 1 : 0 }}
            transition={{ duration: 0.3 }}
        >
            <i className="fas fa-plus text-xl"></i>
        </motion.button>
    );

    return (
        <div className="container mx-auto flex flex-col lg:flex-row gap-4 p-2">
            {width >= 1024 ? <Aside className="w-1/4" /> : <BottomNav />}

            <main className="w-full lg:w-3/4 dark:bg-neutral-900 mt-4">
                <header className="mb-6">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="flex items-center justify-between mb-4"
                    >
                        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Главная</h2>
                        {/*<span className="text-pink-600 font-semibold text-sm px-2 bg-pink-100 dark:bg-pink-200 rounded-full cursor-pointer">*/}
                        {/*    Layout*/}
                        {/*</span>*/}
                    </motion.div>
                    {/*<div className="flex items-center space-x-4 overflow-auto">*/}
                    {/*    {Array(10).fill('').map((_, i) => (*/}
                    {/*        <div className="flex flex-col items-center" key={i}>*/}
                    {/*            <img*/}
                    {/*                src="https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png"*/}
                    {/*                alt="Profile"*/}
                    {/*                className="w-14 h-14 rounded-full border-2 border-violet-400"*/}
                    {/*            />*/}
                    {/*            <p className="text-sm font-medium text-gray-900 dark:text-white">User {i + 1}</p>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </header>

                <PostList posts={posts} setPosts={setPosts} />

                {renderFloatingButton()}
            </main>

            {width > 1024 && <RightSidebar />}
            {isModalOpen && (
                <PostModal closeModal={closeModal}>
                    <CreatePost addPost={addPost} closeModal={closeModal} />
                </PostModal>
            )}
        </div>
    );
}

export default Home;
