import React, { useEffect, useState } from 'react';
import MusicPlayer from './MusicPlayer';

const TrackList = () => {
    const [tracks, setTracks] = useState([]);

    useEffect(() => {
        const fetchTracks = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/tracks', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            setTracks(data);
        };

        fetchTracks();
    }, []);

    return (
        <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-3xl mx-auto">
            <div className="flex justify-start p-4">
                <button
                    onClick={() => window.location.href = '/upload-track'}
                    className="flex items-center px-6 py-2 bg-gray-800 text-white rounded-md shadow-md hover:bg-gray-700 transition">
                    <span className="text-lg font-semibold mr-2">+</span>
                    Добавить песню
                </button>
            </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Track List</h2>
            {tracks.length === 0 ? (
                <p className="text-gray-500">No tracks available.</p>
            ) : (
                <ul>
                    {tracks.map(track => (
                        <li key={track.id}
                            className="p-4 bg-white mb-4 rounded-lg shadow-sm flex justify-between items-center">
                            <div>
                                <h3 className="font-bold text-lg">{track.title}</h3>
                                <p className="text-gray-600">{track.artist}</p>
                            </div>

                        </li>
                    ))}
                </ul>

            )}
            <MusicPlayer />
            
        </div>
    );
};

export default TrackList;
