import React, { useState } from 'react';

const UploadTrack = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    const [artist, setArtist] = useState('');
    const [error, setError] = useState('');

    const handleUpload = async () => {
        if (!file || !title || !artist) {
            setError('All fields are required!');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', title);
        formData.append('artist', artist);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload track');
            }

            alert('Track uploaded successfully!');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-md mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Upload a Track</h2>
            {error && <p className="text-red-500">{error}</p>}
            <input
                type="text"
                placeholder="Track Title"
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full p-2 mb-4 border border-gray-300 rounded-lg"
            />
            <input
                type="text"
                placeholder="Artist Name"
                onChange={(e) => setArtist(e.target.value)}
                className="block w-full p-2 mb-4 border border-gray-300 rounded-lg"
            />
            <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                className="block w-full p-2 mb-4 border border-gray-300 rounded-lg"
            />
            <button onClick={handleUpload} className="w-full p-2 bg-blue-500 text-white rounded-lg">
                Upload
            </button>
        </div>
    );
};

export default UploadTrack;
