import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useWindowSize from "../hooks/useWindowSize";
import { motion } from "framer-motion";

function PostModal({ closeModal, children }) {
    const { width } = useWindowSize();
    const modalRef = useRef(null);
    const [dragging, setDragging] = useState(false);
    const [startTouchY, setStartTouchY] = useState(0);
    const [y, setY] = useState(0);
    const [isClosing, setIsClosing] = useState(false);

    const handleTouchStart = (e) => {
        setStartTouchY(e.touches[0].clientY);
        setDragging(false);
    };

    const handleTouchMove = (e) => {
        const touchY = e.touches[0].clientY;
        const movementY = touchY - startTouchY;
        if (movementY > 10) {
            setDragging(true);
            setY(movementY > 300 ? 300 : movementY);
        }
    };

    const handleTouchEnd = () => {
        if (y > 150) {
            onClose();
        } else {
            setDragging(false);
            setY(0);
        }
    };

    const onClose = () => {
        setIsClosing(true);
        setY(300);
        setTimeout(closeModal, 300);
    };

    const overlayVariants = {
        visible: { opacity: 1, backdropFilter: "blur(5px)" },
        hidden: { opacity: 0, backdropFilter: "blur(0px)" }
    };

    const mobileModalVariants = {
        open: { y: 0 },
        closed: { y: '100%' }
    };

    const desktopModalVariants = {
        open: { opacity: 1, scale: 1 },
        closed: { opacity: 0, scale: 0.9 }
    };

    if (width < 768) {
        return (
            <>
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-40 z-40"
                    initial="hidden"
                    animate="visible"
                    variants={overlayVariants}
                    transition={{ duration: 0.3 }}
                    onClick={onClose}
                ></motion.div>

                <motion.div
                    ref={modalRef}
                    className="fixed bottom-0 left-0 w-full bg-white shadow-xl rounded-t-2xl dark:bg-neutral-800 z-50"
                    style={{ y: isClosing ? 300 : y }}
                    initial="closed"
                    animate={dragging ? { y: y } : isClosing ? { y: 300 } : "open"}
                    variants={mobileModalVariants}
                    transition={{ type: 'spring', stiffness: 150, damping: 20 }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className="p-6 relative">
                        <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4"></div>
                        <button
                            onClick={onClose}
                            className="absolute top-3 right-3 bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
                        >
                            <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
                        </button>
                        <h2 className="text-xl font-bold mb-4 text-center dark:text-white">Создание поста</h2>
                        {children}
                    </div>
                </motion.div>
            </>
        );
    }

    return (
        <>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-40 z-50 flex items-center justify-center"
                initial="hidden"
                animate="visible"
                variants={overlayVariants}
                transition={{ duration: 0.3 }}
                onClick={onClose}
            />

            <motion.div
                className="fixed inset-0 flex items-center justify-center z-50"
                initial="closed"
                animate={isClosing ? "closed" : "open"}
                variants={desktopModalVariants}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
                <motion.div
                    className="bg-white p-8 rounded-lg shadow-2xl relative w-full max-w-lg md:max-w-2xl dark:bg-neutral-800"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className='flex justify-between items-center mb-4'>
                        <h1 className="text-xl md:text-2xl font-extrabold dark:text-white">Создание поста</h1>
                        <button
                            onClick={onClose}
                            className="absolute top-3 right-3 bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
                        >
                            <i className="fas fa-times text-gray-700 dark:text-gray-300"></i>
                        </button>
                    </div>
                    {children}
                </motion.div>
            </motion.div>
        </>
    );
}

PostModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default PostModal;
