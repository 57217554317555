import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Pusher from "pusher-js";
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";

const NAV_ITEMS = [
    { path: "/profile", icon: "fa-regular fa-user", label: "Профиль" },
    { path: "/messages", icon: "fa-regular fa-message", label: "Сообщения" },
    { path: "/posts/all", icon: "fas fa-list", label: "Посты" },
    { path: "/friends", icon: "fas fa-user-group", label: "Друзья" },
    { path: "/notifications", icon: "fa-regular fa-bell", label: "Уведомления" },
    { path: "/search", icon: "fas fa-search", label: "Поиск" },
    { path: "/groups", icon: "fa-regular fa-compass", label: "Группы" },
    { path: "/music", icon: "fas fa-music", label: "Музыка" },
    { path: "/services", icon: "fas fa-globe", label: "Сервисы" },
    { path: "/settings", icon: "fas fa-cog", label: "Настройки" },
];

function Aside() {
    const { isLoggedIn } = useContext(AuthContext);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { width } = useWindowSize();

    const fetchUnreadMessages = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setUnreadMessagesCount(0);
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setUnreadMessagesCount(response.data?.unread_count || 0);
        } catch {
            setUnreadMessagesCount(0);
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            fetchUnreadMessages();

            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, { cluster: "eu" });
            const channel = pusher.subscribe("messages");
            channel.bind("new-message", fetchUnreadMessages);

            const intervalId = setInterval(fetchUnreadMessages, 30000);

            return () => {
                channel.unbind("new-message", fetchUnreadMessages);
                pusher.unsubscribe("messages");
                clearInterval(intervalId);
            };
        }
    }, [isLoggedIn, fetchUnreadMessages]);

    if (width < 768) {
        return <BottomNav unreadMessagesCount={unreadMessagesCount} />;
    }

    return (
        <aside className="w-1/4 p-4 sticky top-0 h-screen overflow-y-auto flex flex-col">
            <ul className="space-y-2">
                {NAV_ITEMS.map(({ path, icon, label }) => (
                    <li key={path} className="group">
                        <Link
                            to={path}
                            className="flex items-center p-2 rounded-lg transition-transform duration-200 hover:scale-105 bg-white dark:bg-neutral-800 group-hover:shadow-md group-hover:bg-gray-50 dark:group-hover:bg-neutral-700"
                        >
                            <i className={`${icon} mr-3 text-lg text-gray-600 dark:text-gray-300 group-hover:text-purple-600 dark:group-hover:text-purple-400`}></i>
                            <div className="flex justify-between items-center w-full">
                                <span className="font-medium text-gray-800 dark:text-gray-200 group-hover:text-purple-600 dark:group-hover:text-purple-400">
                                    {label}
                                </span>
                                {path === "/messages" && unreadMessagesCount > 0 && (
                                    <span className="bg-red-500 text-white text-xs w-5 h-5 flex justify-center items-center rounded-full shadow-sm">
                                        {unreadMessagesCount}
                                    </span>
                                )}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default Aside;
