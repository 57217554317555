import React from 'react';

const MusicPlayer = () => {

    return (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white dark:bg-neutral-900 dark:text-white z-50 rounded-t-xl shadow-md">
            <div className="flex items-center justify-between space-x-4">
                <div className="flex items-center space-x-2">
                    <img src="https://picsum.photos/50" className="w-10 h-10 rounded-full" alt="" />
                    <div className="flex flex-col">
                        <span className="font-bold text-sm">Song title</span>
                        <span className="text-xs text-gray-500 dark:text-gray-400">Artist name</span>
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <button className="bg-gray-200 dark:bg-gray-700 rounded-full p-2 hover:bg-gray-300 dark:hover:bg-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <button className="bg-blue-500 dark:bg-blue-700 rounded-full p-2 hover:bg-blue-600 dark:hover:bg-blue-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5-3l2 2 4-4M4.5 11.5L1 10H1.5C1.5 9.5 2 9 2.5 9L4 10.5M1 5C1 4.5 1.5 4 2 4h11c.5 0 1 .5 1 1v1.5C12 6.5 11.5 7 11 7h-3.5C9.5 7 9 6.5 9 6C9 5.5 9.5 5 10 5h5.5C16.5 5 17 5.5 17 6C17 6.5 16.5 7 16 7h-3.5C14.5 7 14 6.5 14 6C14 5.5 14.5 5 15 5h1V19c0 .5-.5 1-1 1H3c-.5 0-1-.5-1-1V5h1c.5 0 1 .5 1 1Z" />
                        </svg>
                    </button>
                    <button className="bg-gray-200 dark:bg-gray-700 rounded-full p-2 hover:bg-gray-300 dark:hover:bg-gray-600">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7M4 12h16" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MusicPlayer;