import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

function FriendListForProfile() {
  const [friends, setFriends] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const openModal = (friendId) => {
    setFriendToDelete(friendId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFriendToDelete(null);
  };

  const handleDeleteFriend = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/friends/${friendToDelete}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(friends.filter(friend => friend.friend.id !== friendToDelete));
      closeModal();
    } catch (error) {
      console.error('Error deleting friend:', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) fetchFriends();
  }, [isLoggedIn]);

  return (
      <div className="mx-auto justify-center w-full max-w-3xl p-4 dark:bg-neutral-900 dark:shadow-none">
        <Link
            to="/friends"
            className="text-lg font-semibold dark:text-white mb-4 block text-center"
        >
          Друзья
        </Link>

        <ul className="flex space-x-6 overflow-x-auto pb-2">
          {friends.length === 0 ? (
              <p className="text-gray-500 dark:text-gray-400 text-center w-full">У вас нет друзей</p>
          ) : (
              friends.map(friend => (
                  <li
                      key={friend.id}
                      className="flex flex-col items-center relative group cursor-pointer hover:scale-105 transition-transform"
                      onClick={() => handleViewProfile(friend.friend.id)}
                  >
                    <img
                        src={friend.friend.avatar
                            ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}`
                            : `https://ui-avatars.com/api/?name=${friend.friend.name}&background=random`
                        }
                        alt={friend.friend.name || 'Friend Avatar'}
                        className="w-16 h-16 rounded-full object-cover mb-1 border-2 border-gray-200 dark:border-gray-600 shadow-md"
                    />
                    <p className="text-xs font-medium text-gray-700 dark:text-gray-300 truncate w-20 text-center">
                      {friend.friend.name || 'Unnamed'}
                    </p>
                    {friend.is_online && (
                        <span
                            className="absolute bottom-12 right-4 w-3 h-3 rounded-full bg-green-400 border-2 border-white dark:border-neutral-900"></span>
                    )}
                  </li>
              ))
          )}
        </ul>
      </div>
  );
}

export default FriendListForProfile;
