import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostList from '../PostList';
import {ClipLoader} from "react-spinners";

function ProfilePosts({ userId }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    params: {
                        userId,
                    },
                });
                setPosts(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке постов:', error);
                setError('Не удалось загрузить посты.');
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchPosts();
        }
    }, [userId]);

    if (loading) {
        return <div className="flex items-center justify-center min-h-screen">
                    <ClipLoader color="#6B46C1" loading={loading} size={75}/>
               </div>;
    }

    if (error) {
        return <p className="text-center text-red-500 dark:text-red-400">{error}</p>;
    }


    return (
        <div className={`w-full mx-auto dark:bg-neutral-900 rounded-lg dark:text-gray-100`}>
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-6">
                Посты
            </h1>
            {posts.length === 0 ? (
                <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
                    <div className="flex items-center justify-center mb-4">
                        <i className="fas fa-ghost text-5xl text-gray-700 dark:text-gray-300 animate-blob2"></i>
                    </div>
                    <p className="text-center text-gray-700 dark:text-white">Посты не найдены.</p>
                </div>
            ) : (
                <PostList posts={posts}/>
            )}
        </div>
    );
}

export default ProfilePosts;
