import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import BackLink from "./BackLink";
import { debounce } from "lodash"; // Добавление debounce

const Search = () => {
    const [searchResults, setSearchResults] = useState({ users: [], posts: [], groups: [] });
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { width } = useWindowSize();
    const [showSearchResults, setShowSearchResults] = useState(false);

    // Дебаунсинг для предотвращения слишком частых запросов
    const handleSearchDebounced = debounce(async () => {
        if (searchValue.trim() === '') {
            setSearchResults({ users: [], posts: [], groups: [] });
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params: {
                    query: searchValue,
                    category: selectedCategory,
                },
            });
            setSearchResults(response.data);
            setShowSearchResults(true);
        } catch (error) {
            console.error(error);
        }
    }, 500); // Задержка 500мс перед отправкой запроса

    useEffect(() => {
        handleSearchDebounced();
        // Очистка debounce при размонтировании компонента
        return () => handleSearchDebounced.cancel();
    }, [searchValue, selectedCategory]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/categories`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setCategories(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCategories();
    }, []);

    if (width < 768) {
        return <BottomNav />;
    }

    const categoryNames = {
        profile: 'Пользователи',
        posts: 'Посты',
        group: 'Группы',
    };

    // Проверка на отсутствие результатов
    const isEmptyResults = Object.values(searchResults).every(result => result.length === 0);

    return (
        <div className="container mx-auto p-4 relative dark:bg-neutral-900 min-h-screen">
            <BackLink />
            <div className="sticky top-0 bg-white dark:bg-neutral-800 shadow-md py-4 px-6 z-10 rounded-full mt-4">
                <form onSubmit={(e) => e.preventDefault()} className="flex items-center gap-4">
                    <input
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Поиск пользователя, поста или сообщества"
                        className="flex-1 bg-gray-200 dark:bg-neutral-700 rounded-full px-4 py-2 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-600"
                    />
                    <button
                        type="submit"
                        className="bg-violet-600 text-white px-6 py-2 rounded-full hover:bg-violet-700 transition"
                    >
                        Поиск
                    </button>
                </form>
            </div>
            <div className="px-6 py-4">
                <div className="flex gap-2 mb-4">
                    {categories.map((category) => (
                        <button
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                            className={`py-2 px-4 rounded-full font-medium transition ${
                                selectedCategory === category.id
                                    ? 'bg-violet-600 text-white'
                                    : 'bg-gray-200 dark:bg-neutral-500 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-neutral-600'
                            }`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
                {showSearchResults ? (
                    isEmptyResults ? (
                        <p className="text-center text-gray-500 dark:text-gray-400">
                            Ничего не найдено.
                        </p>
                    ) : (
                        <div>
                            {Object.entries(searchResults).map(([key, results]) => (
                                results.length > 0 && (
                                    <div key={key} className="mb-6">
                                        <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2">
                                            {categoryNames[key] || key.charAt(0).toUpperCase() + key.slice(1)}
                                        </h3>
                                        <div className="grid gap-4">
                                            {results.map((result) => (
                                                <Link
                                                    to={`/${key}/${result.id}`}
                                                    key={result.id}
                                                    className="block bg-white dark:bg-neutral-800 rounded-lg shadow hover:shadow-md transition p-4"
                                                >
                                                    <p className="text-gray-900 dark:text-white font-medium">
                                                        {result.name || result.title || result.description}
                                                    </p>
                                                    {result.avatar && (key === 'profile') && (
                                                        <img src={result.avatar} alt={result.name} className="w-10 h-10 rounded-full" />
                                                    )}
                                                    {result.created_at && (key === 'posts') && (
                                                        <div className="flex items-center">
                                                            <p className="text-sm text-gray-600 dark:text-gray-400">{result.title}</p>
                                                            <p className="text-sm text-gray-600 dark:text-gray-400">{new Date(result.created_at).toLocaleDateString()}</p>
                                                        </div>
                                                    )}
                                                    {result.description && (key === 'group') && (
                                                        <div className="flex items-center">
                                                            <p className="text-sm text-gray-600 dark:text-gray-400">{result.description}</p>
                                                        </div>
                                                    )}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    )
                ) : (
                    <p className="text-center text-gray-500 dark:text-gray-400">
                        Начните поиск, чтобы увидеть результаты.
                    </p>
                )}
            </div>
        </div>
    );
};

export default Search;
