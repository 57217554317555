import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import useWindowSize from "../hooks/useWindowSize";
import { motion, AnimatePresence } from 'framer-motion';
import ConfirmLogout from "./ConfirmLogout";

function Header() {
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const [userData, setUserData] = useState(user);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn && !userData) {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Ошибка при загрузке данных пользователя!', error);
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn, userData]);

  const handleLogout = () => {
    logout();
    setUserData(null);
    setDropdownVisible(false);
    navigate('/login');
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const changeTheme = () => {
    document.documentElement.classList.toggle('dark');
    const currentTheme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    localStorage.setItem('theme', currentTheme);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleLogoutClick = () => {
    setDropdownVisible(false);
    setShowConfirmLogout(true);
  };

  const closeConfirmLogout = () => {
    setShowConfirmLogout(false);
  };

  const confirmLogout = () => {
    setShowConfirmLogout(false);
    handleLogout();
  };

  const defaultAvatar = `https://ui-avatars.com/api/?name=${userData?.name}&background=random`;

  return (
      <header className="bg-white dark:bg-neutral-800 shadow-sm border-b border-gray-200 dark:border-neutral-800">
        <div className="container mx-auto flex items-center justify-between py-2 px-4 md:px-6">
          <div className="flex items-center">
            <Link to="/" className="text-xl font-semibold text-gray-800 dark:text-white">
              <div className="flex items-center">
              <img src={`/logo.png`} alt="Chattify" className="h-6 w-auto mr-2" />
              chattify
              </div>
            </Link>
            <div className="relative ml-2 group">
              <span
                  className="text-pink-600 font-semibold text-sm rounded-full px-2 bg-pink-100 dark:bg-pink-200 cursor-pointer">Beta</span>
              <div
                  className="absolute bottom--6 left-1/2 transform -translate-x-1/2 mb-2 w-max bg-neutral-700 text-white text-xs rounded-lg py-1 px-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p>Приложение в стадии активной разработки!</p>
                <p>Могут возникать непредвиденные ошибки</p>
              </div>
            </div>
          </div>
          <nav className="flex items-center space-x-4 md:space-x-6">
            {!isLoggedIn && (
                <>
                  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Link to="/login" className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                      Войти
                    </Link>
                  </motion.div>
                  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Link to="/register" className="text-gray-800 dark:text-gray-200 hover:text-indigo-600 dark:hover:text-indigo-400 transition text-sm md:text-base">
                      Регистрация
                    </Link>
                  </motion.div>
                </>
            )}
            {isLoggedIn && (
                <div className="relative" ref={dropdownRef}>
                  {userData && (
                      <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
                        <img
                            src={userData.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${userData.avatar}` : defaultAvatar}
                            alt="Avatar"
                            className="w-8 h-8 rounded-full object-cover border border-gray-300 dark:border-gray-600"
                        />
                        <p className="hidden md:block text-gray-800 dark:text-white font-medium text-sm md:text-base">
                          {userData.name}
                        </p>
                        <motion.i
                            className={`fas fa-chevron-down text-gray-600 dark:text-gray-400 transition-transform duration-200 ${dropdownVisible ? 'rotate-180' : ''}`}
                            animate={{ rotate: dropdownVisible ? 180 : 0 }}
                            transition={{ duration: 0.2 }}
                        />
                      </div>
                  )}
                  <AnimatePresence>
                    {dropdownVisible && (
                        <motion.div
                            className="absolute right-0 mt-2 w-56 bg-white dark:bg-neutral-900 text-gray-800 dark:text-gray-200 rounded-lg shadow-xl overflow-hidden z-50"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.2 }}
                        >
                          <Link
                              to="/profile"
                              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                              onClick={closeDropdown}
                          >
                            <i className="fa-regular fa-user mr-2"></i>Профиль
                          </Link>
                          {width < 768 && (
                              <Link
                                  to="/settings"
                                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                                  onClick={closeDropdown}
                              >
                                <i className="fas fa-cog mr-2"></i>Настройки
                              </Link>
                          )}
                          <button
                              onClick={changeTheme}
                              className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-neutral-700 transition"
                          >
                            <i className="fas fa-palette mr-2"></i>Сменить тему
                          </button>
                          <button
                              onClick={handleLogoutClick}
                              className="block w-full text-left px-4 py-2 text-red-500 hover:bg-red-100 dark:hover:bg-red-600/10 transition"
                          >
                            <i className="fas fa-sign-out-alt mr-2"></i>Выйти
                          </button>
                        </motion.div>
                    )}
                  </AnimatePresence>
                </div>
            )}
          </nav>
        </div>
        <ConfirmLogout
            isOpen={showConfirmLogout}
            onClose={closeConfirmLogout}
            onConfirm={confirmLogout}
        />
      </header>
  );
}

export default Header;
