import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';

const notifyError = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

const SuggestionList = ({ suggestions, onSelect }) => {
  if (suggestions.length === 0) return null;

  return (
      <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-neutral-700 dark:border-gray-600 dark:text-gray-300 mt-2 max-h-60 overflow-y-auto">
        {suggestions.map((suggestion) => (
            <li
                key={suggestion.id}
                onClick={() => onSelect(suggestion)}
                className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-neutral-600 rounded-lg transition-colors"
            >
              <img
                  src={suggestion.avatar
                      ? `${process.env.REACT_APP_BASE_URL}/storage/${suggestion.avatar}`
                      : `https://ui-avatars.com/api/?name=${suggestion.name}&background=random`}
                  alt={suggestion.name}
                  className="w-8 h-8 rounded-full object-cover mr-3"
              />
              <span>{suggestion.name}</span>
            </li>
        ))}
      </ul>
  );
};

const AddFriend = ({ fetchFriends }) => {
  const [friendName, setFriendName] = useState('');
  const [friendId, setFriendId] = useState(null); // Store selected friend ID
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (friendName.length > 1) {
      fetchSuggestions(friendName);
    } else {
      setSuggestions([]);
      setDropdownVisible(false);
    }
  }, [friendName]);

  const fetchSuggestions = async (query) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends/suggestions`, {
        params: { query },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuggestions(response.data);
      setDropdownVisible(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFriendName(suggestion.name);
    setFriendId(suggestion.id); // Store the friend ID for the POST request
    setDropdownVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post(
          `${process.env.REACT_APP_API_URL}/friends`,
          { friend_id: friendId }, // Use friend_id instead of friend_name
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
      );
      notifySuccess('Заявка отправлена!');
      setFriendName('');
      setFriendId(null);
      fetchFriends();
    } catch (error) {
      if (error.response) {
        notifyError(error.response.data.error || 'Произошла ошибка при отправке заявки!');
      } else if (error.request) {
        notifyError('Нет ответа от сервера.');
      } else {
        notifyError('Произошла ошибка при отправке заявки.');
      }
    }
  };

  if (!isLoggedIn) {
    return <p className="text-center text-red-500 mt-4">Please log in to add friends.</p>;
  }

  return (
      <div className="container mx-auto p-6 bg-white dark:bg-neutral-800 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Добавить друга</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <input
                id="friendName"
                type="text"
                value={friendName}
                onChange={(e) => setFriendName(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:border-gray-600 dark:text-gray-300"
                placeholder="Введите имя друга"
            />
            {isDropdownVisible && (
                <SuggestionList
                    suggestions={suggestions}
                    onSelect={handleSuggestionClick}
                />
            )}
          </div>
          <button
              type="submit"
              className="w-full bg-violet-600 text-white py-2 px-4 rounded-lg hover:bg-violet-700 transition-colors"
          >
            Добавить
          </button>
        </form>
      </div>
  );
};

export default AddFriend;
